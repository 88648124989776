<template>
  <div v-show="!isShowFooter" class="menu-button">
    <nuxt-link
      v-for="(item, index) in menu"
      :key="index"
      class="menu-item"
      :class="{
        active: checkActive(item, item.activeUrl)
      }"
      :to="item.url"
      @click="clickMenuItem(item)"
    >
      <div class="action-wrap__icon">
        <CommonBaseImg
          class="icon"
          :src="checkActive(item, item.activeUrl) ? item.iconActive : item.icon"
          :alt="item.name"
        />
      </div>
      <div class="text">{{ item.name }}</div>
    </nuxt-link>
  </div>
</template>
<script setup lang="ts">
import CommonBaseImg from '~/components/common/base-img'
import { PAGE_URLS, SPORTS_URLS, LOBBY_GAME_URLS } from '~/config/page-url'
import { BOTTOM_MENU } from '~/constants/menu'
import { useAppStore } from '~/store/app'
import { useToggleMenu } from '~/composables/useToggleMenu'
import { IMenuBottom } from '~/types/menu.type'

const route = useRoute()
const store = useAppStore()
const { toggleMenuAccount } = useToggleMenu()

const menu = computed(() => {
  return BOTTOM_MENU.filter((item) => item.name !== (route.path === PAGE_URLS.HOME ? 'Trang Chủ' : 'Khuyến Mãi'))
})

const clickMenuItem = (item: IMenuBottom) => {
  if (!item?.url) {
    toggleMenuAccount()
  }
}
const checkActive = (item: IMenuBottom, activeUrl: string) => {
  if (item.url === BOTTOM_MENU[1].url) {
    return false
  } else if (route.query.type === 'lottery' && route.name !== 'casino') {
    return route.fullPath === item.url || route.fullPath.includes(item.url)
  } else if (route.path === LOBBY_GAME_URLS.QUAY_SO && activeUrl === '/game') {
    return false
  } else {
    return route.fullPath.includes(item.url) || route.fullPath.includes(activeUrl)
  }
}

const isShowFooter = computed(() => {
  const values = Object.values(SPORTS_URLS).slice(1)
  return values.includes(route.path)
})
</script>
<style scoped lang="scss" src="assets/scss/components/mobile/common/menu-bottom/index.scss" />
